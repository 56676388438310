<template>
<div class="row">
  <h1> Nonconformist hackathon </h1>
  <p>
    Hackatoshi’s Flying Circuit is an intervention of cypherpunks into both virtual and physical public space.
    It's an weekend long exertion aiming to concentrate inspiration, knowledge and skill to exploit confortism and
    compliance with status quo.
<!--    <a> Read the full manifesto... </a>-->
  </p>
  <hr>
  <h2> Competition tracks </h2>
  <p>
    This year's Flying Circuit focuses on topics such as cloud society and urban hacktivism.
    Hackers can choose to either to build new solutions or break an existing nonsense.
    The hacking teams will have to prove a combination of their technical skill, artistic ability and scientific diligence.
  </p>
  <br>
  <div class="twelve columns">
    <h3> Out (Public space) </h3>
    <p> In this track, hackers can perform physical hacks in the public space or build tools potentially impacting day-to-day reality of sub-communities living in Prague. The city is your canvas, the law abiding citizens are your spectators!</p>
    <p>
      We can't tell you what exactly you should be doing, we are not asking you to break (in) particular thing (place).
      We ask you to wander outside of the venue walls and bring the hackathon to the nothing suspecting law abiding citizens.
      Improve their lives, improve your urban experience, find a new way to move in the concrete jungle or create it!
    </p>
    <p>
      Make lifehacks not war! Create new pathways, plant gardens, deploy monumental art or hidden gems only people
      with an open mind can witness, be the creator of your other people's reality!
    </p>
  </div>

  <div class="twelve columns">
    <h3> In (Cloud societies) </h3>
    <p> Humans have always enjoyed finding ways in which to survive better in their geophysical surroundings; It’s called evolution. </p>
    <p> But lately, a new world has emerged. One in the cloud, where borders, politics, physical restraints, and physical realities pale compared to the fact that you have any and all information orbiting the globe at the speed of light, leading to unprecedented social change, connections, and ideas. Welcome to the rise of the cloud civilisation. However, a lot of people find evolution and hacking their way through this digital jungle hard; because the basics of ‘digital survival’ were never taught in school and also because the world of technology has accelerated at such a rapid pace in the last years, bringing with it new technological challenges and opportunities like crypto, blockchain, AI, and other buzzwords which are ceasing to be buzzwords and becoming our everyday reality. </p>
    <p>
      Hack your way through the cloud; a new, undiscovered world awaits and it’s your turn to conquer it. Metaverse
      is yours to conquer!
    </p>
  </div>

</div>

</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>

</style>