<template>
<div class="row">
  <h1> Covid rules </h1>
  <p> Antigen tests will be provided on-site for free. All attendees will be asked to pass a negative on-site test
    before entering the building. That's to ensure the event will happen in a safe environment for everyone. Masks are
    recommended but not mandatory.</p>
</div>

</template>

<script>
export default {
  name: "Covid"
}
</script>

<style scoped>

</style>