<template>
<div class="row">
  <h1> Prize pool </h1>
  <p>
    Our judges will select a winner of each track. The winning teams will split the prize money and numerous perks.
  </p>
  <br>
  <div class="six columns">
    <h3> Inside || Online </h3>
    <ul>
      <li> $500 in ETH </li>
      <li> 12 months of access to Paioneers </li>
      <li> Train tickets to Brno </li>
    </ul>

  </div>
  <div class="six columns">
    <h3> Outside || Offline </h3>
    <ul>
      <li> $500 in ETH </li>
      <li> 1 year membership in Bordel </li>
      <li> Train tickets to Berlin </li>
    </ul>
  </div>
</div>

</template>

<script>
export default {
  name: "SignUp"
}
</script>

<style scoped>
ul {
  list-style-type: none;
  text-align: center;
  font-size: 22px;
}


</style>