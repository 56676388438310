<template>
<div class="row">
  <h1> Organizers </h1>
  <div class="four columns">
    <a href="https://paioneers.cz" target="_blank">
      <img src="../assets/logos/paioneers.png">
    </a>
  </div>
  <div class="four columns">
    <a href="https://paralelnipolis.cz" target="_blank">
      <img src="../assets/logos/paralelnipolis-logo-white.png">
    </a>
  </div>
  <div class="four columns">
    <a href="https://bordel.space" target="_blank">
      <img src="../assets/logos/bordel.png">
    </a>
  </div>
</div>

</template>

<script>
export default {
  name: "SignUp"
}
</script>

<style scoped>
.row, .columns {
  text-align: center;
  horiz-align: center;
}

img {
  width: 200px;
  margin-bottom: 20px;
}


</style>